//copy contents from assets/jss/common/material-kit-react.js
//Dark theme reference
//https://www.bootstrapdash.com/demo/material-admin-dark/jquery/template/demo_1/index.html
//material components
//https://material-components.github.io/


import React from "react"
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

// @material-ui/icons

import "fontsource-roboto"
//@FontAwsome
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'

// core components
import GridContainer from "@components/Grid/GridContainer.js";
import GridItem from "@components/Grid/GridItem.js";
import LayoutMain from "@components/layout/layoutMain.js";
import PageMaincontent from "@components/layout/section/common/page/mainContent.js";


import { layoutPageStyle } from "assets/jss/views/layout/layout.js";
import { globalPageStyle } from "assets/jss/common/globalPage.js"

import ThreeMysticApesThemeHelper from "three_mystic_apes_extension/util/themeHelper.js";

library.add(fab);

export const useLayoutStyles = makeStyles(layoutPageStyle)
export const usePageStyles = makeStyles(globalPageStyle)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pageNode: PropTypes.object.isRequired,
  props: PropTypes.object,
}

//GTag Code
// (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
// new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
// j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
// 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
// })(window,document,'script','dataLayer','GTM-WF2NJHR');

export function Layout({ children, pageNode, location, pageContext, ...props }) {

  const classes = useLayoutStyles()
  const classesPage = usePageStyles()

  const themeVariation = ThreeMysticApesThemeHelper.GetThemeVariation();
  if (typeof window !== 'undefined'){
    localStorage.setItem("themeVariation", themeVariation);
  }


  return (
    <LayoutMain pageNode={pageNode} classes={classes} classesPage={classesPage} location={location} pageContext={pageContext} {...props}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <PageMaincontent pageNode={pageNode} classes={classes} classesPage={classesPage} location={location} pageContext={pageContext}>
            {children}
          </PageMaincontent>
        </GridItem>
      </GridContainer>
    </LayoutMain>
  )
}